<template>
    <div class="bottom">
      <div style="padding: 50px 110px 50px 210px;">
        <div class="flex justify_content">
          <div class="left">
            <div class="title">{{language === 'chinese' ? '联系我们' : 'Please Contact Us'}}</div>
            <div class="content">
              <div class="item flex align_items"><img :src="wx1" alt=""><span style="text-decoration: underline; cursor: pointer;" @click="contactHandle">YuanNianJinFu</span></div>
              <div class="item flex align_items"><img :src="wx2" alt=""><span>400-1600-112</span></div>
              <div class="item flex align_items"><img :src="wx3" alt=""><span>mkt@genesisfin.net</span></div>
              <div class="item flex align_items">
                <img :src="wx4" alt="">
                <span>{{language === 'chinese' ? '上海市浦东新区浦东南路1118号鄂尔多斯国际大厦9楼' : '9F, Ordos International Building, No. 1118 Pudong South Rd, Pudong New Area, Shanghai'}}</span>
              </div>
              <div class="item flex align_items">
                <div style="width: 40px;"></div>
                <span>{{language === 'chinese' ? '北京市朝阳区SK大厦A座22层2206号' : 'No.2206, Block A, SK Building, Chaoyang District, Beijing'}}</span>
              </div>
              <!-- <div class="item flex align_items">
                <div style="width: 40px;"></div>
                <span>{{language === 'chinese' ? '香港尖沙咀广东道9号海港城港威大厦6座29楼2907-8室' : 'Suites 2907-8,29/F,Tower 6,The Gateway,Harbour City,9 Canton Road,Tsim Sha Tsui,Hong kong'}}</span>
              </div> -->
            </div>
          </div>
          <div class="right">
            <div class="title">{{language === 'chinese' ? '关注我们' : 'Pay Attention To Us'}}</div>
            <div class="flex justify_content" style="padding-top: 25px;" v-if="language === 'chinese'">
              <div class="flex align_items"><img :src="wx5" alt=""><span>公众号</span></div>
              <div class="flex align_items"><img :src="wx6" alt=""><span>知乎</span></div>
            </div>
            <div class="flex align_items" style="padding-top: 40px;" v-if="language === 'chinese'"><img :src="wx7" alt=""><span>哔哩哔哩</span></div>
            <div class="flex align_items" style="padding-top: 40px;" v-if="language === 'english'"><img :src="wx11" alt="" style="width: 130px;height: 130px;"><span>WeChat</span></div>
          </div>
        </div>
        <div class="record_info">Copyright © 2015-2025 | 版权所有 上海元年金融信息服务有限公司 | 上海市通信管理局 沪ICP备15028722号-2 | 沪公网安备31010902003073号</div>
      </div>
      <!-- 弹窗 -->
      <div class="dialog_connection" v-if="contactStatus">
        <img :src="close" alt="" class="close" @click="closeHandle">
        <img :src="wx11" alt="" class="connection_img">
        <p>{{ language === 'chinese' ? '欢迎扫码联系官方客服' : 'Contact Us'}}</p>
      </div>
    </div>
</template>
<script>
    import contact_1 from '../assets/contact_1.png';
    import contact_2 from '../assets/contact_2.png';
    import contact_3 from '../assets/contact_3.png';
    import contact_4 from '../assets/contact_4.png';
    import contact_5 from '../assets/contact_5.png';
    import contact_6 from '../assets/contact_6.png';
    import contact_7 from '../assets/contact_7.png';
    import wx11 from '../assets/wx4.png';
    import close from '../assets/close.png';

    export default {
        name: 'FooterBottom',
        props:{
          language: {
            type: String,
            default: 'chinese'
          }
        },
        data() {
          return {
            wx11,
            close,
            wx1:contact_1,
            wx2:contact_2,
            wx3:contact_3,
            wx4:contact_4,
            wx5:contact_5,
            wx6:contact_6,
            wx7:contact_7,
            contactStatus: false,
          }
        },
        methods: {
          contactHandle() {
            this.contactStatus = true;
          },
          closeHandle() {
            this.contactStatus = false;
          }
        }
    }
</script>
<style scoped lang="scss">
  .bottom {
    background: url("../assets/footer_bg.png");
    height: 552px;
    color: #fff;
    .title {
      font-size: 30px;
      padding-top: 10px;
    }
    .left {
      width: 70%;
      .content {
        padding-top: 30px;
        .item {
          padding-top: 15px;
          img {
            width: 40px;
            height: 40px;
          }
          span {
            font-size: 24px;
            padding-left: 10px;
          }
        }
      }
    }
    .right {
      width: 30%;
      img {
        width: 90px;
        height: 90px;
      }
      span {
        font-size: 24px;
        padding-left: 25px;
      }
    }
    .record_info {
      padding-top: 110px;
    }
    // 弹窗
    .dialog_connection {
      width: 542px;
      height: 446px;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 2;
      text-align: center;
      .close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      .connection_img {
        width: 203px;
        height: 203px;
        margin-top: 15%;
      }
      p {
        font-size: 32px;
        color: #21374E;
        padding-top: 20px;
      }
    }
  }
</style>
