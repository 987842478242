<template>
  <div class="home_page">
    <topMenu :language="language"></topMenu>
    <div class="logo_bg">
      <el-image :src="url">
        <div slot="placeholder">图片加载中</div>
      </el-image>
    </div>
    <div class="about_brief" @click="handleAnchor">
      <div class="brief_top">
        <h4>About Genesis</h4>
        <span class="more">Learn More >></span>
      </div>
      <div style="font-size: 16px;line-height: 2;text-align: justify">
        Shanghai Genesis Financial Information Services Co., Ltd. (Genesis) was founded in 2015 which is an independent third-party fund administrator with ISAE3402 certification and as an approved TA/FA service provider regulated under Asset Management Association of China Filing No.(A00044).
      </div>
    </div>
    <!-- 元年介绍 -->
    <div class="padding110 introduce_bg" style="padding-bottom: 30px;">
      <div class="yn_introduce" id="introduce">
        <div class="left">
          <div class="yn_introduce_title">Genesis Introduction</div>
          <div class="yn_introduce_content">
            <p>Over the last 10 years journey, Genesis has been grown organically with an accumulative AUM over 100 billion RMB by providing end-to-end solutions for types of clients in alternative industry. Driven by entrepreneurial spirit along the way, we committed to client service as where when we started, timely and best-fit operation solutions. Till end of 2013, we expanded headcounts to over 150 professionals located among in Shanghai, Beijing, and Hong Kong.</p>
            <p style="padding-top: 12px;">Genesis adheres to the mission of “empowering the professional operation of capital management, boosting up the wealth of compatriots ” and practices the corporate values of “service, truthfulness, innovation, execution, integrity”. We strive to become the most reliable independent third-party fund administrator and this is what Genesis feels proud of and to achieve.</p>
          </div>
        </div>
        <div class="left">
          <div class="yn_introduce_title">OUR service</div>
          <div class="yn_introduce_tag flex justify_content">
            <div class="item flex align_items"
            v-for="(item, index) in introduceList"
            :key="index"
            @mouseover="changeStyle(true)"
            @mouseout="changeStyle(false)"
            @click="introduceHandle(item.path)">
              <img :src="item.icon" alt="" class="icon"><span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" id="footer">
      <FooterBottom :language="language"></FooterBottom>
    </div>
  </div>
</template>

<script>
import url from '../assets/home_page_bg.jpg';
import introduce_icon1 from '../assets/introduce_icon1.png';
import introduce_icon2 from '../assets/introduce_icon2.png';
import wx4 from '../assets/wx4.png';
import close from '../assets/close.png';


import {positionList,associationNewsList,companyNewsList} from './constData'
import TopMenu from './menuEnglish'
import FooterBottom from './footer'

export default {
  name: 'HomePage',
  props: {

  },
  components: {TopMenu,FooterBottom},
  data() {
    return {
      url,
      wx4,
      close,
      screenHeight: 0,
      fontSize: 16,
      introduceList: [
        {
          icon: introduce_icon1,
          title: 'Domestic Fund Administration Services',
          path: '/services/RMBEnglish'
        },
        {
          icon: introduce_icon2,
          title: 'USD Fund Administration Services',
          path: '/services/dollarEnglish'
        }
      ],
      contactStatus: false,
      isHover: false,
      language: 'english',
      companyNewsList,
      associationNewsList,
      positionList,
    }
  },
  created() {
    this.screenHeight = window.innerHeight;
  },
  mounted() {
    this.updateFontSize(); // 初始化时更新字体大小
    window.addEventListener('resize', this.updateFontSize);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.updateFontSize);
  },
  methods:{
    updateFontSize() {
      const ratio = window.devicePixelRatio || 1;
      this.fontSize = Math.round(16 * ratio); // 根据像素比例调整字体大小
    },
    handleResize() {
      this.screenHeight = window.innerHeight;
    },
    handRowClick(row){
      window.open(`/position?id=${row.id}`)
    },
    handAssociationNewsRowClick(row){
      window.open(row.link);
    },
    handCompanyNewsClick(row){
      window.open(row.link);
    },
    handleAnchor(){
      document.querySelector('#introduce').scrollIntoView({
        behavior:'smooth',
        block: 'center',
      })
    },
    introduceHandle(path) {
      this.$router.push(path).then(() => {
        window.scrollTo(0, 0);
      });
    }, 
    contactHandle() {
      this.contactStatus = true;
    },
    closeHandle() {
      this.contactStatus = false;
    },
    changeStyle(isHover) {
      this.isHover = isHover;
    },
  }
}
</script>

<style scoped lang="scss">
  /*大背景*/
  .logo_bg{
    position: relative;
  }
  .logo_bg .el-image{
    height: 100%;
    width: 100%;
  }
  .yn_introduce_title {
    color: #B01F24;
    font-size: 52px;
    padding-bottom: 40px;
    font-weight: bold;
  }
  .about_brief{
    position: absolute;
    right:4%;
    top: 64%;
    width: 654px;
    // height: 265px;
    background: #21374e99;
    padding: 36px 84px 36px 70px;
    color: #E5E5E5;
    cursor: pointer;
  }
  h4{
    font-size: 28px;
  }
  .more{
    color: #E5E5E5!important;
  }
  .brief_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }
  /*元年介绍*/
  .introduce_bg {
    background: url("../assets/introduce_bg.png");
  }
  .yn_introduce {
    .left {
      padding: 60px 0 30px;
      .yn_introduce_content {
        color: #252525;
        font-size: 20px;
        line-height: 35px;
        text-align: justify;
      }
      .yn_introduce_tag {
        .item {
          width: 46%;
          cursor: pointer;
          border: 1px solid #21374E;
          font-size: 30px;
          height: 112px;
          padding: 0 50px;
          color: #21374E;
          .icon {
            width: 46px;
            height: 46px;
            margin-right: 15px;
          }
        }
        .item:hover {
          background: #21374E;
          color: #fff;
        }
        .item:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .introduce_btn {
    display: inline-block;
    background: #fff;
    padding: 12px 40px;
    margin-top: 30px;
    color: #21374E;
    font-weight: bold;
    cursor: pointer;
  }
  .introduce_btn:hover {
    color: #B01F24;
  }
  // 加入我们
  .join_my {
    padding-top: 40px;
    .yn_join {
      flex-wrap: wrap;
      .join_item{
        margin-right: 4%;
        font-size: 20px;
        border: 1px solid #252525;
        padding: 12px 0 12px 15px;
        margin-top: 40px;
        cursor: pointer;
        transition: transform 0.3s;
      }
      .join_item:hover {
        background-color: #21374E;
        color: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
      }
      .join_item:nth-child(1), .join_item:nth-child(6){
        width: 19%;
      }
      .join_item:nth-child(3), .join_item:nth-child(5), .join_item:nth-child(8), .join_item:nth-child(10){
        width: 12%;
      }
      .join_item:nth-child(2), .join_item:nth-child(7){
        width: 26%;
      }
      .join_item:nth-child(4), .join_item:nth-child(9){
        width: 15%;
      }
      .join_item:nth-child(5n){
        margin-right: 0;
      }
    }
  }
  // 新闻资讯
  .new {
    .slide {
      display: flex;
      justify-content: space-between;
      .image-container {
        width: 30%; /* 每个图片容器宽度占比 */
        margin-right: 5%; /* 最后一个图片容器无间距 */
        cursor: pointer;
        .new_img {
          width: 506px;
          height: 216px;
        }
        .new_name {
          font-size: 23px;
          color: #252525;
          margin-top: 15px;
          line-height: 35px;
        }
      }
      .image-container:last-child {
        margin-right: 0; /* 最后一个图片容器无间距 */
      }
      .image-container:hover .new_name{
        color: #B01F24;
        text-decoration: underline;
      }
    }
  }
  // 弹窗
  .dialog_connection {
    width: 542px;
    height: 446px;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    text-align: center;
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .connection_img {
      width: 203px;
      height: 203px;
      margin-top: 15%;
    }
    p {
      font-size: 32px;
      color: #21374E;
      padding-top: 20px;
    }
  }
</style>

